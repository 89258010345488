import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, Nav, Row, Col, Tab, Modal, Button } from 'react-bootstrap'

import ReactMarkdown from 'react-markdown'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'
import HeaderBlock from '../../components/header-block'
import FilesPageCard from '../../components/files-page-card'

import { animate } from '../../styles/common.module.css'


const FilesPage = ({ data }) => {

    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <MainLayout pageTitle="Files">

            <Seo title="Files" description="Downloadable files and resources for ai03 projects" />

            <Container>
                <HeaderBlock header="Files and Resources">
                    <p>By using any of the files provided, you agree to ai03's Open Source Keyboard File Clauses.</p>
                    <Button variant="primary" onClick={handleShow}>
                        Read clause
                    </Button>
                </HeaderBlock>
                <Tab.Container defaultActiveKey={data.allStrapiCategory.nodes[0].name}>
                    <Row>
                        <Col lg={3} className="mb-3">
                            <Nav variant="pills" className={`flex-column`}>
                                {
                                    data.allStrapiCategory.nodes.map(category => (
                                        <Nav.Item key={category.id} className="me-0 me-lg-4">
                                            <Nav.Link className={`text-lg-start text-center rounded`} eventKey={category.name} role="button">{category.name}</Nav.Link>
                                        </Nav.Item>
                                    ))
                                }
                            </Nav>
                        </Col>
                        <Col lg={9}>
                            <Tab.Content>
                                {
                                    data.allStrapiCategory.nodes.map(category => (
                                        <Tab.Pane eventKey={category.name} key={category.id}>
                                            <Row className="justify-content-lg-start justify-content-center">
                                                {
                                                    category.projects.sort((a, b) => {
                                                        let aUpper = a.boardname.toUpperCase();
                                                        let bUpper = b.boardname.toUpperCase();
                                                        return aUpper === bUpper ? 0 : (aUpper > bUpper ? 1 : -1);
                                                    }).map(board => (
                                                        <Col xl={4} md={6} key={board.id} className={`${animate} mb-4`}>
                                                            <FilesPageCard board={board} downloadsList={data.allStrapiDownload.nodes.filter(download => download.project.boardname === board.boardname)} />
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Tab.Pane>
                                    ))
                                }
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>ai03's Open Source Keyboard File Clauses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactMarkdown children={data.strapiAssets.openfilesclause} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>

        </MainLayout>


    )
}

export const query = graphql`
query {
    allStrapiCategory(sort: {fields: ordering, order: ASC}) {
        nodes {
            id
            name
            projects {
                id
                slug
                boardname
                publishdate(formatString: "YYYY-MM-DD")
                cover {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                aspectRatio: 1.618
                                transformOptions: {fit: COVER, cropFocus: CENTER}
                                placeholder: BLURRED
                                width: 400
                                quality: 95
                            )
                        }
                    }
                }
            }
        }
    }
    allStrapiDownload {
        nodes {
            id
            title
            file {
                url
            }
            project {
                boardname
            }
        }
    }
    strapiAssets {
        openfilesclause
    }
}

`

export default FilesPage