import * as React from 'react'
import { Link } from 'gatsby'
import { Card, Collapse } from 'react-bootstrap'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import DownloadsList from './downloads-list'

import { dontUnderline } from '../styles/common.module.css'
import { cardStackButton } from './files-page-card.module.css'


class FilesPageCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            board: props.board,
            downloadsList: props.downloadsList,
            buttonText: "View all files",
        };
    }

    handleClick() {

        if (this.state.collapse) {
            this.setState({ buttonText: "View all files", collapse: !this.state.collapse });
        } else {
            this.setState({ buttonText: "Hide files", collapse: !this.state.collapse });
        }

    }

    render() {
        return (
            <Card className={`shadow h-100 rounded text-center overflow-hidden`}>
                <Link to={"/projects/" + this.state.board.slug}>
                    <Card.Img as={GatsbyImage} image={getImage(this.state.board.cover.localFile)} alt="" variant="top" className={`rounded-top`} />
                </Link>
                <Card.Body>
                    <Link to={"/projects/" + this.state.board.slug} className={dontUnderline}>
                        <h3 className={`card-title cursor-pointer mb-1 mt-1`}>{this.state.board.boardname}</h3>
                    </Link>
                    <p className={`card-text pt-2 text-muted mb-1`}>Announced {this.state.board.publishdate}</p>
                </Card.Body>
                <ul className={`list-group list-group-flush `}>
                    {
                        this.props.downloadsList.length > 0
                            ?
                            <div>
                                <span tabIndex={0} className={`list-group-item list-group-item-action border-0 user-select-none ${cardStackButton}`} role="button" onClick={() => this.handleClick()} onKeyDown={() => this.handleClick()}>
                                    {this.state.buttonText}
                                </span>
                                <Collapse in={this.state.collapse}>
                                    <div>
                                        <DownloadsList downloadsList={this.props.downloadsList} />
                                    </div>
                                </Collapse>

                            </div>
                            :
                            <div>
                                <span disabled className={`disabled list-group-item list-group-item-action cursor-pointer border-0 mt-1 mb-1`}>
                                    No files uploaded
                                </span>
                            </div>
                    }
                </ul>
            </Card>
        )
    }
}

export default FilesPageCard