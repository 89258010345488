import * as React from 'react'
import { Table } from 'react-bootstrap'

const DownloadsList = ({ downloadsList }) => {

    return (

        <Table responsive="md" striped className={`m-0`}>
            <tbody>
                {
                    downloadsList.map(download => (
                        <tr key={download.id}>
                            <td>{download.title}</td>
                            <td><a href={download.file.url}>Download</a></td>
                        </tr>
                    ))
                }

            </tbody>
        </Table>

    )
}
export default DownloadsList